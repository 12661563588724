<template>
  <el-dialog width="75%" v-model="dialogVisible" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false" :destroy-on-close="true">
    <div class="dialogCenter fs-flex fs-flex-align-center fs-flex-center">
      <div class="brandImg1">
        <img style="width: unset; max-width: 100%" :src="`/api/ffs/file/` + productBrandImage[imageIndex]" alt="" />
      </div>
      <div class="brandRight1">
        <div :class="imageIndex ===0 ? 'handleBrand2' : 'handleBrand'" @click="prev">
          <ArrowLeft class="handleBrandIcon" />
        </div>
        <div :class="imageIndex === productBrandImage.length-1 ? 'handleBrand2' : 'handleBrand'" @click="next">
          <ArrowRight class="handleBrandIcon" />
        </div>
        <div class="handleBrand" @click="close">
          <Close class="handleBrandIcon" />
        </div>
      </div>
    </div>

    <template #footer>
      <span class="dialog-footer">
        <div class="brandFooter1 fs-flex fs-flex-align-center fs-flex-center" @click="go" style="width: 216px">
          <span>查看品牌产品</span>
          <el-icon style="margin-left: 15px">
            <ArrowRight />
          </el-icon>
        </div>
        <div v-if="brandItem.tmall !='' && brandItem.tmall !=null" class="brandFooter1 fs-flex fs-flex-align-center fs-flex-center" @mouseover="() => handleButtonOver(1)"
          @mouseleave="() => handleButtonLeave(1)">
          <div class="footer_img_box" style="height: 32px; width: 51px">
            <a class="newsArticle" target="_blank" :href="brandItem.tmall">
              <img style="width: unset; max-width: 100%" :src="tmall" alt="" />
            </a>
          </div>
        </div>
        <div v-if="(brandItem.jd !='' && brandItem.jd !=null)" class="brandFooter1 fs-flex fs-flex-align-center fs-flex-center" @mouseover="() => handleButtonOver(2)"
          @mouseleave="() => handleButtonLeave(2)">
          <div class="footer_img_box" style="width: 70px; height: 27px">
            <a class="newsArticle" target="_blank" :href="brandItem.jd">
              <img style="width: unset; max-width: 100%" :src="JD" alt="" />
            </a>
          </div>
        </div>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { ref, watch } from "vue";
import TmallDefault from "../assets/about/brand/tmall.png";
import JDDefault from "../assets/about/brand/JD.png";
import JDActive from "../assets/about/brand/JDActive.png";
import TmallActive from "../assets/about/brand/tmallActive.png";
import { useRouter } from "vue-router";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    brandIndex: {
      type: Number,
      default: 1,
    },
    brand: {
      type: Object,
    }
  },
  methods: {
    handleButtonOver(index) {
      if (index == 1) {
        this.tmall = TmallActive;
      } else if (index == 2) {
        this.JD = JDActive;
      }
    },
    handleButtonLeave(index) {
      if (index == 1) {
        this.tmall = TmallDefault;
      } else if (index == 2) {
        this.JD = JDDefault;
      }
    },
  },

  setup(props, ctx) {
    const dialogVisible = ref(false);
    const brandItem = ref('');
    const imageIndex = ref(0)
    const productBrandImage = ref();
    const tmall = ref(TmallDefault);
    const JD = ref(JDDefault);
    const router = useRouter();

    const close = () => {
      ctx.emit("update:visible", false);
    };

    const prev = () => {
      if (imageIndex.value === 0) {
        return;
      } else {
        imageIndex.value = imageIndex.value - 1;
      }
    };

    const next = () => {
      if (imageIndex.value === (productBrandImage.value.length - 1)) {
        return;
      } else {
        imageIndex.value = imageIndex.value + 1;
      }
    };

    const go = () =>{
      router.push({
        name:'product',
        params:{
          brand:JSON.stringify(brandItem.value)
        }
      })
    };

    const confirm = () => {
      console.log("你点击了确定按钮");
      ctx.emit("update:visible", false);
    };

    watch(
      () => props.visible,
      (val) => {
        console.log(props.visible, val);
        console.log(props.brand);
        imageIndex.value = 0;
        brandItem.value = props.brand;
        productBrandImage.value = brandItem.value.productBrandImage;
        dialogVisible.value = val;
      }
    );

    return {
      dialogVisible,
      tmall,
      JD,
      confirm,
      close,
      prev,
      next,
      brandItem,
      productBrandImage,
      imageIndex,
      go,
    };
  },
};
</script>


<style lang="less" >
/** 背景遮罩 */
.el-overlay {
  background-color: rgba(0, 0, 0, 0.85);
}

.el-dialog {
  background: none;
  box-shadow: none;
}

.dialogCenter {
  .brandImg1 {
    width: 90%;
    max-width: 1200px;
  }

  .brandRight1 {
    margin-left: 22px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.handleBrand {
  display: inline-block;
  width: 105px;
  height: 214px;
  max-height: 214px;
  background: #1f1f26;
  text-align: center;
  position: relative;
  color: #525253;
}

.handleBrand:hover {
  color: #ffffff;
  background: #35353e;
}

.handleBrand:nth-child(2) {
  margin: 14px 0;
}

.handleBrand2 {
  display: inline-block;
  width: 105px;
  height: 214px;
  max-height: 214px;
  background: #1f1f26;
  text-align: center;
  position: relative;
  color: #525253;
}

.handleBrand2:hover {
  color: #ffffff;
  background: #35353e;
  cursor: not-allowed;
}

.handleBrand2:nth-child(2) {
  margin: 14px 0;
}

.handleBrandIcon {
  width: 40px;
  height: 40px;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 5%;

  .footer_img_box {
    display: inline-block;
    height: 32px;
    width: 51px;
  }
}

.brandFooter1 {
  width: 156px;
  height: 52px;
  border: 1px solid rgba(250, 250, 250, 0.5);
  border-radius: 26px;
  color: #fff;
  background: rgba(0, 0, 0, 0.6);
}

.brandFooter1:hover {
  color: #00a0e9;
  border: 1px solid rgba(0, 160, 233, 0.5);
}

@media screen and (max-width: 1700px) {
  .handleBrand {
    height: 190px;
  }
  .handleBrand2 {
    height: 190px;
  }
}

@media screen and (max-width: 1600px) {
  .handleBrand {
    height: 180px;
  }.handleBrand2 {
    height: 180px;
  }
}

@media screen and (max-width: 1500px) {
  .handleBrand {
    height: 160px;
  }
  .handleBrand2 {
    height: 160px;
  }
}

@media screen and (max-width: 1400px) {
  .handleBrand {
    height: 150px;
  }
  .handleBrand2 {
    height: 150px;
  }
}

@media screen and (max-width: 1300px) {
  .handleBrand {
    height: 135px;
  }
  .handleBrand2 {
    height: 135px;
  }
}

@media screen and (max-width: 1200px) {
  .handleBrand {
    height: 120px;
  }
  .handleBrand2 {
    height: 120px;
  }
}

@media screen and (max-width: 1100px) {
  .handleBrand {
    height: 100px;
  }
  .handleBrand2 {
    height: 100px;
  }
}

@media screen and (max-width: 1000px) {
  .handleBrand {
    height: 80px;
  }
  .handleBrand2 {
    height: 80px;
  }
}

@media screen and (max-width: 870px) {
  .dialogCenter {
    flex-direction: column;

    .brandRight1 {
      width: 100%;
      margin: 0;
      flex-direction: row;
      justify-content: space-around;

      .handleBrand:nth-child(2) {
        margin: 0 14px;
      }

      .handleBrand {
        height: 40px;
        width: 25%;
      }
      .handleBrand2:nth-child(2) {
        margin: 0 14px;
      }

      .handleBrand2 {
        height: 40px;
        width: 25%;
      }
    }

    .handleBrandIcon {
      width: 20px;
      height: 20px;
    }
  }
}

@media screen and (max-width: 700px) {
  .dialog-footer {
    flex-direction: column;
  }
}
</style>
