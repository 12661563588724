<template>
  <div class="about">
    <div class="bannerBox">
      <img src="/about/introduction/bg.png" loading="eager" class="bannerImg" />
      <div class="introduce_container">
        <div class="about_introduce">
          <p class="company_name">富胜家居</p>
          <p class="company_introduction">
            {{ PROFILE_STR }}
          </p>
        </div>
      </div>
    </div>
    <div class="about_second" id="introduction">
      <div class="second_title">{{ advantageResources.ADVANTAGES.title }}</div>
      <div class="about-product-types">
        <template v-for="(prod, index) in advantageResources.ADVANTAGES.products" :key="index">
          <div class="about-product-type-item">
            <div class="second_info">
              <div>
                <img :src="advantageResources.PRODUCT_IMAGES[index]" loading="eager">
              </div>
              <div class="second_info_text">
                {{ prod.title }}
              </div>
            </div>
          </div>
        </template>
      </div>
      <div class="second_footer">
        <el-row :gutter="24">
          <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
            <div class="second_bottom fs-flex fs-flex-column fs-flex-align-center fs-flex-center">
              <div>
                <img style="width: unset; max-width: 100%" :src="advantageResources.ADV_FEATURE1_IMG" loading="eager"/>
              </div>
              <div class="second_bottom_text1">{{ advantageResources.ADVANTAGES.feature1.title }}</div>
              <div class="second_bottom_text2"
                v-for="(line, index) in advantageResources.ADVANTAGES.feature1.contentLines" :key="index">
                {{ line }}
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
            <div class="second_bottom fs-flex fs-flex-column fs-flex-align-center fs-flex-center">
              <div>
                <img style="width: unset; max-width: 100%" :src="advantageResources.ADV_FEATURE2_IMG" loading="eager" />
              </div>
              <div class="second_bottom_text1">{{ advantageResources.ADVANTAGES.feature2.title }}</div>
              <div class="second_bottom_text2"
                v-for="(line, index) in advantageResources.ADVANTAGES.feature2.contentLines" :key="index">
                {{ line }}
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
            <div class="second_bottom fs-flex fs-flex-column fs-flex-align-center fs-flex-center" style="border: none">
              <div>
                <img style="width: unset; max-width: 100%" :src="advantageResources.ADV_FEATURE3_IMG" loading="eager"/>
              </div>
              <div class="second_bottom_text1">{{ advantageResources.ADVANTAGES.feature3.title }}</div>
              <div class="second_bottom_text2"
                v-for="(line, index) in advantageResources.ADVANTAGES.feature3.contentLines" :key="index">
                {{ line }}
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="serve_customer" id="serveCustomer">
      <div class="serve_customer_title">
        <div class="header_text">{{ customerResources.CUSTOMERS.title }}</div>
        <div class="header_text_english">/ CUSTOMER</div>
      </div>
      <div class="serve_customer_panel">
        <div class="serve_customer_info" v-for="(logo, index) in customerResources.CUSTOMER_LOGOS" :key="index">
          <img style="width: unset; max-width: 80%" :src="logo" loading="eager" />
        </div>
      </div>
    </div>
    <div class="brands" id="brand">
      <div class="brands_title">
        <div class="header_text">旗下品牌</div>
        <div class="header_text_english">/ BRAND</div>
      </div>
      <div class="brand_box">
        <div v-for="(item, index) in (showMorBrand && sliceBrandList) || productBrandList" :key="index">
          <div class="brand_info fs-flex fs-flex-align-center fs-flex-center" @click="handleBrandClick(index, item)">
            <img style="width: unset; max-width: 80%" :src="`/api/ffs/file/${item.brandIcon.id}`" />
          </div>
        </div>
      </div>

      <div class="fs-flex fs-flex-align-center fs-flex-center" style="margin-top: 72px"
        v-if="(productBrandList.length > 15)" @click="handleShowMoreBrand">
        <div class="more_brand fs-flex fs-flex-align-center fs-flex-center">
          <span>查看更多</span>
          <el-icon v-if="showMorBrand" style="color: #fff; margin-left: 20px">
            <ArrowDown />
          </el-icon>
          <el-icon v-else style="color: #fff; margin-left: 20px">
            <ArrowUp />
          </el-icon>
        </div>
      </div>
    </div>

    <div class="history" id="historys">
      <div class="bigtitle">
        <div class="header_text" style="color: rgba(17, 17, 18, 1)">发展历程</div>
        <div class="header_text_english">/ DEVELOPMENT</div>
      </div>
      <div>
        <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
          <el-tab-pane v-for="(item, index) in developHistory" :name="index" :key="index">
            <template #label>
              {{ item.groupTitle }}
            </template>
            <div class="pagingBox fs-flex">
              <div class="iconStep fs-flex fs-flex-center fs-flex-align-center" style="margin-right: 30px"
                @click="currentSwiper.slidePrev()">
                <el-icon>
                  <ArrowLeft />
                </el-icon>
              </div>
              <div class="iconStep fs-flex fs-flex-center fs-flex-align-center" @click="currentSwiper.slideNext()">
                <el-icon>
                  <ArrowRight />
                </el-icon>
              </div>
            </div>
          </el-tab-pane>
          <div class="historyBox">
            <div ref="mySwiper" class="swiper-container" id="mySwiper">
              <div class="swiper-wrapper history-events-panel">
                <div class="swiper-slide history-event" v-for="(item2, i) in developHistory[activeName]?.companyEvents"
                  :key="i">
                  <div class="mask-container">
                    <img class="history-image" :src="`/api/ffs/file/${item2.eventImage.id}`" alt="" />

                    <div class="detailBottom">
                      <div class="detailYear">
                        {{ item2.eventTitle }}
                      </div>
                      <div class="detailText">
                        {{ item2.eventInfo }}
                      </div>
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </el-tabs>
      </div>
    </div>
    <div class="consulte" id="consulting">
      <div>
        <div class="news-title">
          <div class="header_text" style="color: rgba(17, 17, 18, 1)">企业资讯</div>
          <div class="header_text_english" style="margin-bottom: 30px">/ NEWS</div>
        </div>
      </div>
      <div class="news-container">
        <div class="newsPagingBox fs-flex">
          <div class="iconStep fs-flex fs-flex-center fs-flex-align-center" style="margin-right: 30px" @click="prev">
            <el-icon>
              <ArrowLeft />
            </el-icon>
          </div>
          <div class="iconStep fs-flex fs-flex-center fs-flex-align-center" @click="next">
            <el-icon>
              <ArrowRight />
            </el-icon>
          </div>
        </div>
        <div class="newsBox">
          <template v-for="(item, index) in companyNews" :key="index">
            <a class="newsArticle" target="_blank" :href="item.link">
              <img class="newsImage" :src="`/api/ffs/file/${item.titleImage.id}`" alt="" />
              <div class="detailBottom">
                <div class="detailText">{{ item.title }}</div>
              </div>
            </a>
          </template>
        </div>
      </div>
      <el-backtop :bottom="100">
        <div class="backtop">
          <!-- <img src="@/assets/about/backtop.png" alt="" /> -->
          <div class="arrow"></div>
          TOP
        </div>
      </el-backtop>
    </div>

    <div class="side-nav-menu fs-flex fs-flex-column hidden-xs-only">
      <template v-for="(item, index) in sideNavMenus " :key="index">
        <div class="menu_divider_box">
          <div class="menu_box">
            <span v-if="item.label" class="menu_text" :class="menuType !== item.name ? 'menu_link' : undefined"
              @click="handleMenuClick(item.name)">
              {{ item.label.substring(0, item.label.length / 2) }}
              <br />
              {{ item.label.substring(item.label.length / 2) }}
            </span>
            <template v-if="menuType === item.name">
              <div class="step"></div>
            </template>
            <template v-else>
              <div class="step step_default" @click="handleMenuClick(item.name)"></div>
            </template>
          </div>

          <el-divider v-if="index < (sideNavMenus.length - 1)" direction="vertical" class="divider"></el-divider>
        </div>
      </template>

    </div>
    <AboutDialog v-model:visible="flag" v-model:brandIndex="brandIndex" v-model:brand="brandItem" />
  </div>
</template>

<script>
import request from "@/utils/request";
import { mapGetters } from "vuex";
import { inject, ref, watch } from "vue";
import Swiper from "swiper";
import "swiper/css/swiper.css";

// import INTRO_BG_IMAGE from "/public/about/introduction/bg.png";
import PROFILE_STR from "/public/about/introduction/profile.json";

import { ADV_BG_IMG, ADV_FEATURE1_IMG, ADV_FEATURE2_IMG, ADV_FEATURE3_IMG, PRODUCT_IMAGES, ADVANTAGES } from "./advantages.js";
import { CUSTOMER_LOGOS, CUSTOMERS } from "./customers.js";

import AboutDialog from "../components/AboutDialog.vue";

export default {
  setup() {
    const pageIndicator = inject("pageIndicator");
    pageIndicator.currentPageName = "aboutus";
    pageIndicator.lightMode = false; // 导航栏背景适应深色内容模式；
    pageIndicator.headerTransparent = false; // 导航栏背景全透明；
    pageIndicator.headerGradient = false; // 导航栏背景渐变；
    console.log("inject pageIndicator ...", pageIndicator);

    const flag = ref(false);
    const activeName = ref(0);

    const open = () => {
      flag.value = true;
    };

    watch(
      () => flag.value,
      (val) => {
        console.log("监听flag值得变化:", val);
      }
    );

    return {
      flag,
      activeName,
      open,
    };
  },
  data() {

    const sideNavMenus = [
      { name: "introduction", label: "企业简介" },
      { name: "serveCustomer", label: "服务客户" },
      { name: "brand", label: "旗下品牌" },
      { name: "historys", label: "发展历程" },
      { name: "consulting", label: "企业资讯" },
      { name: "bottom", label: undefined },
    ];

    return {
      // 侧边栏浮动导航菜单；
      sideNavMenus: sideNavMenus,

      // 企业简介背景图；
      // INTRO_BG_IMAGE: INTRO_BG_IMAGE,
      PROFILE_STR: PROFILE_STR,

      // 优势特点；
      advantageResources: {
        ADV_BG_IMG, ADV_FEATURE1_IMG, ADV_FEATURE2_IMG, ADV_FEATURE3_IMG, PRODUCT_IMAGES, ADVANTAGES
      },

      // 服务客户；
      customerResources: { CUSTOMER_LOGOS, CUSTOMERS },

      brandIndex: 0, //品牌下标
      brandItem: Object,
      secondTextArr: ["厨房收纳", "衣柜收纳", "厨房用水", "家居移门", "基础五金", "智能家居", "照明系统", "木工工具", "智慧门店", "家居礼品"],

      newsArr: [
        {
          src: "../assets/about/news/1.png",
          text: "生活可以简单而高级-兰伯特",
        },
        {
          src: "../assets/about/news/2.png",
          text: "生活可以简单而高级-兰伯特",
        },
        {
          src: "../assets/about/news/3.png",
          text: "生活可以简单而高级-兰伯特",
        },
        {
          src: "../assets/about/news/4.png",
          text: "生活可以简单而高级-兰伯特",
        },
        {
          src: "../assets/about/news/5.png",
          text: "生活可以简单而高级-兰伯特",
        },
        {
          src: "../assets/about/news/6.png",
          text: "生活可以简单而高级-兰伯特",
        },
      ],
      currentSwiper: null,

      productBrandList: [],
      sliceBrandList: [],
      showMorBrand: false,

      //是否展示完全发展历程描述
      showDetailText: false,

      developHistory: [],
      companyNews: [],
      current: {
        page: 0,
        size: 6,
      },

      menuType: "",

      aboutInfo: {},
    };
  },
  components: { AboutDialog },
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters(["screenWidth"]),
    newsArrFun() {
      return function (iten) {
        return this.newsArr.slice(iten * 3, iten * 3 + 3);
      };
    },
    newsArrFun1() {
      return function (iten) {
        return this.newsArr.slice(iten * 2, iten * 2 + 2);
      };
    },
    newsArrFun2() {
      return function (iten) {
        return this.newsArr.slice(iten * 1, iten * 1 + 1);
      };
    },
  },
  methods: {
    handleMenuClick(type) {
      if (this.menuType === type) {
        return;
      }
      this.menuType = type;
      document.getElementById(type).scrollIntoView({
        behavior: "smooth", // 平滑过渡
      });
    },

    // 显示更多旗下品牌
    handleShowMoreBrand() {
      this.showMorBrand = !this.showMorBrand;
    },

    // handleClick(tab, event) {
    handleClick() {
        this.updateSwiper();
    },
    getData() {
      // request({
      //   url: "/api/aboutus",
      //   method: "get",
      // })
      //   .then((res) => {
      //     if (res.code == 0) {
      //       this.aboutInfo = res.data;
      //     }
      //   })
      //   .catch((e) => {
      //     console.log(e);
      //   });
    },
    // 旗下品牌
    getProductBrandList() {
      request({
        url: "/api/ui/productBrand/list",
        method: "get",
      })
        .then((res) => {
          if (res.code == 0) {
            this.productBrandList = res.data.map((item1) => {
              item1.productBrandImage = item1.productBrandImage?.map((item2) => {
                return item2.id;
              });
              return item1;
            });

            // 如果数量超过15，显示“查看更多”按钮；
            this.showMorBrand = false;
            if (this.productBrandList.length > 15) {
              this.showMorBrand = true;
              this.sliceBrandList = this.productBrandList.slice(0, 15);
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    // 发展历程
    getDevelopList() {
      request({
        url: "/api/ui/companyevent/list",
        method: "get",
      })
        .then((res) => {
          if (res.code == 0) {
            this.developHistory = res.data;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    prev() {
      if (this.current.page != 0) {
        this.current.page = this.current.page - 1;
        this.companyNewsPage();
      }
    },
    next() {
      if (this.companyNews.length == 6) {
        this.current.page = this.current.page + 1;
        this.companyNewsPage();
      }
    },

    // 企业资讯
    companyNewsPage() {
      request({
        url: "/api/ui/companynews/page",
        method: "get",
        params: {
          page: this.current.page,
          size: this.current.size,
        },
      })
        .then((res) => {
          if (res.code == 0) {
            this.companyNews = res.data.content;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    handleBrandClick(index, item) {
      // console.log(index, "index");
      // console.log(item, "item");
      this.brandItem = item;
      this.brandIndex = index + 1;
      this.flag = true;
    },
    //初始化swiper
    initSwiper() {
      // const perView=this.screenWidth>850&&4
      //一个页面有多个swiper实例时，为了不互相影响,绑定容器用不同值或变量绑定
      this.currentSwiper = new Swiper("#mySwiper", {
        autoHeight: "true", //开启自适应高度,容器高度由slide高度决定
        slidesPerView: "auto", //视口展示slide数1
        slidesPerGroup: 1,
        slidesPerGroupAuto: true,
        spaceBetween: 30,
      });
    },
    //销毁swiper
    destroySwiper() {
      try {
        this.currentSwiper.destroy(true, true);
      } catch (e) {
        console.log("删除轮播");
      }
    },
    //更新swiper
    updateSwiper() {
      this.destroySwiper();
      setTimeout(() => {
        this.initSwiper();
      }, 500);
      // this.$nextTick(() => {
      //   this.initSwiper();
      // });
    },
    showAll() {
      this.showDetailText = true;
    },
    showPart() {
      this.showDetailText = false;
    },
  },
  beforeMount(){
    this.getData();
    this.getProductBrandList();
    this.getDevelopList();
    this.companyNewsPage();
  },
  mounted() {
    // 锚点；
    const hash = this.$route.hash;
    if (hash) {
      const node = document.querySelector(hash);
      if (node) {
        setTimeout(() => {
          node.scrollIntoView({behavior:"smooth"})
        }, 500);
      }
    }
      setTimeout(() => {
      this.initSwiper();
      }, 1000);
  },
};
</script>

<style>
.el-tabs__header {
  margin: 0px;
}

.el-tabs__nav {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  row-gap: 40px;

}

@media screen and (max-width: 850px) {
  .el-tabs__nav {
    flex-direction: column;
    align-items: center;

    row-gap: 20px;
  }
}

.el-tabs__active-bar {
  /* background-color: #00a0e9;
  top: -3px;
  width: 18% !important;
  height: 5px; */
  visibility: hidden;
}

.el-tabs__item {
  border-width: 1px 0 0 0;
  border-top-style: solid;
  border-top-color: #adadad;

  font-size: 28px;
  font-family: "SimHei";
  width: 80%;
  height: auto;

  padding: 37px 0 0 0;
}

.el-tabs__item.is-active {
  border-top-color: #00a0e9;
  border-top-width: 2px;
}

.el-tabs__item:hover {
  color: #00a0e9;
}

.el-tabs__item.is-active {
  color: #00a0e9;
}

/** 按照设计样式，为模拟时间进度条，把 el-tab 标签底部的分界线条去掉 */
.el-tabs__nav-wrap::after {
  visibility: hidden;
}

.el-backtop {
  color: #00a0e9;
  box-shadow: none;
  background-color: transparent;
}

.el-backtop:hover {
  background-color: transparent;
  box-shadow: none;
}

@media screen and (max-width: 850px) {
  .el-tabs__item {
    font-size: 24px;
    text-align: center;
    padding: 20px 0 0 0;
  }
}

.bigtitle {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 80px;
}
</style>

<style lang="less" scoped>
.bannerBox {
  height: 100vh;
  position: relative;


}

.introduce_container{
    width: 100%;

    position: absolute;
    bottom: 80px;
    left: 0px;
    right: 0px;

    padding: 0 90px;

    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;

}
  .about_introduce {
    width: 100%;
    height: 100%;
    max-width: 1920px;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;

    .company_name {
      font-size: 56px;
      font-weight: 400;
      color: #ffffff;
      line-height: 74px;
      margin-bottom: 46px;
      letter-spacing: 10px;
    }

    .company_introduction {
      font-size: 16px;
      font-weight: 400;
      color: #ffffff;
      line-height: 36px;
      max-width: 592px;
      letter-spacing: 4px;
      text-align: justify;
    }
  }

@media screen and (max-width: 850px) {
  .about_introduce {
    // width: 380px !important;
    align-items: center;

    .company_name {
      font-size: 50px !important;
    }
  }
}

@media screen and (max-width: 400px) {
  .about_introduce {
    width: 100% !important;

    .company_name {
      font-size: 40px !important;
    }
  }
}

// 步骤条设计
.side-nav-menu {
  position: fixed;
  bottom: 166px;
  right: 56px;

  color: #DEDEDE;

  z-index: 4500;
}

.menu_divider_box {
  position: relative;

  .menu_box {
    position: relative;
    margin-bottom: 28px;
    height: 32px;
    width: 36px;

    .menu_text {
      margin-right: 14px;
      display: inline-block;
      font-size: 12px;
      /*font-family: AlibabaPuHuiTi-Regular,system-ui,SimHei,sans-serif;*/
      font-weight: 400;
      color: #DEDEDE;
      line-height: 16px;
      position: absolute;
      width: 60px;
      top: 30px;

    }

    .menu_link {
      cursor: pointer;
    }

    .step {
      display: inline-block;
      width: 8px;
      height: 8px;
      border: 1px solid #dedede;
      border-radius: 50%;
      position: absolute;
      top: 12px;
      right: 0;
      cursor: pointer;
    }

    .step_default {
      width: 3px;
      height: 3px;
      background: #dedede;
      border-radius: 50%;
      right: 3px;
      top: 15px;
    }
  }

  .divider {
    height: 50px;
    position: absolute;
    right: -4px;
    top: 22px;
    border-color: #DEDEDE;
  }
}

.about {
  height: inherit;
  width: 100%;
  overflow: hidden;
}

.swiper-slide {
  width: 461px;
  /*设为固定值*/
  margin: 0px;
}

.more_brand {
  width: 196px;
  height: 52px;
  border: 1px solid #fff;
  border-radius: 26px;

  span {
    font-size: 20px;
    /*font-family: AlibabaPuHuiTi-Regular,system-ui,SimHei,sans-serif;*/
    font-weight: 400;
    color: #fff;
    line-height: 23px;
  }
}

.more_brand::after {
  opacity: 0.5;
}

@media screen and (max-width: 500px) {
  .news-container{
    width: 350px !important;
  }
  .company_introduction{
    font-size: 14px !important;
    line-height: 24px !important;
    width: 350px;
    letter-spacing: 1px !important;
  }
  .introduce_container{
    bottom: 40px !important;
  }
  .swiper-slide {
    width: 350px;
    /*设为固定值*/
  }
  .history-image{
    width: 350px !important;
    height: 220px !important;
  }

  .newsImage{
    width: 350px !important;
    height: 220px !important;
  }

}

.bannerImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about_second {
  background: url("../../public/about/advantages/bg.png") no-repeat center top;
  background-size: cover;
  min-height: 1140px;
  width: 100%;
  padding: 158px 0 112px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .second_title {
    font-size: 60px;
    /*font-family: AlibabaPuHuiTi-Regular,system-ui,SimHei,sans-serif;*/
    font-weight: 400;
    color: #ffffff;
    line-height: 150px;
    text-align: center;
    margin-bottom: 100px;
  }

  .about-product-types {
    width: 100%;
    max-width: 1920px;
    display: flex;
    flex-direction: row;
    column-gap: 32px;
    justify-content: center;
    flex-wrap: wrap;

    padding: 0 32px;

    .about-product-type-item {
      width: 120px;
      display: flex;
      flex-direction: column;
    }
  }

  .second_info {
    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    .second_info_text {
      font-size: 16px;
      /*font-family: AlibabaPuHuiTi-Regular,system-ui,SimHei,sans-serif;*/
      font-weight: 400;
      color: #ffffff;
      line-height: 36px;
    }
  }

  .second_bottom {
    height: 301px;
    border-right: 1px solid rgba(255, 255, 255, 0.2);

    .second_bottom_text1 {
      font-size: 30px;
      /*font-family: AlibabaPuHuiTi-Regular,system-ui,SimHei,sans-serif;*/
      font-weight: 400;
      color: #ffffff;
      line-height: 84px;
    }

    .second_bottom_text2 {
      font-size: 16px;
      /*font-family: AlibabaPuHuiTi-Regular,system-ui,SimHei,sans-serif;*/
      font-weight: 400;
      color: #ffffff;
      line-height: 36px;
    }
  }
}

.second_footer {
  width: 100%;
  max-width: 1920px;
  margin-top: 100px;
  padding: 0 154px;

}

@media screen and (max-width: 850px) {
  .second_title {
    font-size: 40px !important;
    line-height: 100px !important;
    margin-bottom: 50px !important;
  }

  .second_footer {
    padding: 0 !important;
  }
}

.brands {
  width: 100%;
  padding: 65px 10px 65px;
  box-sizing: border-box;
  background-color: #1d1d20;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 80px;

  .brands_title {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .brand_box {
    max-width: 1450px;
    width: 100%;
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    column-gap: 20px;
    row-gap: 20px;

    @media screen and (max-width: 850px) {
      justify-content: center;
    }
  }

  .brand_info {
    width: 265px;
    height: 162px;
    background: #242426;
    position: relative;
    cursor: pointer;

    &:hover {
      transition: all 0.3s;
      transform: scale(1.05);
    }
  }
}

.serve_customer {
  width: 100%;
  padding: 65px 10px 65px;
  box-sizing: border-box;
  background: rgba(14, 14, 17, 1);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 80px;

  .serve_customer_title {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .serve_customer_panel {
    max-width: 1450px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    column-gap: 20px;
    row-gap: 20px;

    @media screen and (max-width: 850px) {
      justify-content: center;
    }
  }

  .serve_customer_info {
    width: 265px;
    height: 162px;
    border: 1px solid rgba(231, 239, 248, 0.2);
    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

  }
}

.backtop {
  // height: 100%;
  // width: 100%;
  background-color: transparent;
  box-shadow: none;
  text-align: center;
  line-height: 36px;
  font-size: 16px;
  font-weight: 500;
  color: #c2c2c2;
  /*font-family: AlibabaPuHuiTi-Regular,system-ui,SimHei,sans-serif;*/
  text-align: center;

  img {
    background-color: transparent;
    width: 25px;
    height: 15px;
  }

  .arrow {
    width: 0;
    height: 0;
    border: 16px solid #c2c2c2;
    border-color: transparent transparent #c2c2c2 transparent;
    // transform: rotate(90deg);
  }
}

.backtop:hover {
  color: #00a0e9;

  .arrow {
    border-color: transparent transparent #00a0e9 transparent;
  }
}

@media screen and (max-width: 1300px) {
  .serve_customer_info {
    width: 24% !important;
  }
}

@media screen and (max-width: 1000px) {
  .serve_customer_info {
    width: 33% !important;
  }
}

@media screen and (max-width: 800px) {
  .serve_customer_info {
    width: 49% !important;
  }
}

@media screen and (max-width: 650px) {
  .serve_customer_info {
    width: 100% !important;
  }
}

@media screen and (max-width: 500px) {
  .serve_customer_title {
    padding-left: 10px !important;

    .header_text {
      font-size: 40px;
    }
  }
}

.header_text {
  height: 52px;
  font-size: 56px;
  /*font-family: AlibabaPuHuiTi-Regular,system-ui,SimHei,sans-serif;*/
  font-weight: 400;
  color: #ffffff;
  line-height: 72px;
}

.header_text_english {
  height: 20px;
  font-size: 26px;
  /*font-family: AlibabaPuHuiTi-Regular,system-ui,SimHei,sans-serif;*/
  font-weight: 300;
  color: #898994;
  line-height: 72px;
  margin-top: 24px;
}

.history {
  width: 100%;
  padding: 65px 100px 40px;
  box-sizing: border-box;
  background-color: #ffffff;
}

.consulte {
  width: 100%;
  padding: 0px 92px 94px;
  box-sizing: border-box;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pagingBox {
  margin: 20px 0 20px;
  width: 1450px;
}

.iconStep {
  width: 40px;
  height: 40px;
  border: 1px solid #adadad;
  border-radius: 50%;
  color: #adadad;
  font-size: 20px;
  cursor: pointer;
}

.historyBox {
  width: 100%;
}

.history-events-panel {
  height: auto !important;

  display: flex;
  flex-direction: row;
  //flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 30px;
  row-gap: 30px;

  @media screen and (max-width: 850px) {
    //justify-content: center;
    justify-content: flex-start;
  }
}

.history-event {
  padding: 0px;
  margin: 0px !important;
  height: fit-content;
}

.history-image {
  width: 461px;
  height: 381px;
  border-radius: 20px;

  object-fit: cover;
}

.historyDetail {
  width: 24%;
}

.news-container {
  width: 100%;
  max-width: 1450px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  
}

.newsPagingBox {
  margin: 20px 0 20px 0px;
  //max-width: 1450px;
  width: 100%;
}

.newsBox {
  max-width: 1450px;
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  column-gap: 20px;
  row-gap: 20px;
  width: 100%;

  @media screen and (max-width: 850px) {
    justify-content: center;
  }
}

.news-title {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.newSwitch {
  display: inline-block;
  line-height: 60px;
}

.newsDetail {
  width: 33%;
}

.newsArticle {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.newsImage {
  width: 450px;
  height: 305px;
  border-radius: 20px;

  object-fit: cover;
}

.detailBottom {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 16px;
  padding: 16px 0 0 0;

  color: #111112;
}

.detailBottom:hover {
  color: rgba(0, 160, 233, 1);
}

.detailYear {
  font-size: 24px;
  font-weight: 400;
}

.detailText {
  height: 98px !important;
  font-size: 17px;
  /*font-family: AlibabaPuHuiTi-Regular,system-ui,SimHei,sans-serif;*/
  font-weight: 400;
  white-space: nowrap; //不换行
  text-overflow: ellipsis; //超出部分添加省略号
  overflow: hidden; //超出部分隐藏
  width: 100%;
}

.detailText:hover {
  white-space: unset;
}

@media screen and (max-width: 850px) {

  .brands,
  .history {
    padding: 80px 20px;
  }

  .header_text {
    font-size: 50px;
  }

  .header_text_english {
    font-size: 20px;
  }
}

@media screen and (max-width: 992px) {
  .about_second {
    padding: 50px 0 !important;
  }
}

@media screen and (max-width: 730px) {
  .second_title {
    font-size: 30px !important;
  }
}
</style>
>
